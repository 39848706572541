:root {
    --content-max-width: 1146px;
}

body {
    /*font-family: 'Roboto', Segoe UI, Helvetica, Microsoft JhengHei, Arial,*/
    /*    'sans-serif';*/
    overflow-y: scroll;
    background-color: #fff;
}

*:focus {
    outline: none;
    text-decoration: none;
}

a:hover {
    outline: none;
    text-decoration: none;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.pf-text-center {
    text-align: center;
}

.pf-title-1 {
    font-size: 30px;
    line-height: 1.3em;
    margin: 0 auto;
    color: #333;
}

.pf-text-1 {
    line-height: 1.6em;
    font-size: 1em;
    color: #212529;
}

.pf-margin-bottom-10 {
    margin-bottom: 10px;
}

.pf-margin-bottom-20 {
    margin-bottom: 20px;
}

.text-justify {
    text-align: justify !important;
}

.pf-font-unset {
    font-size: unset;
    font-weight: unset;
}

.pf-margin-unset {
    margin: unset;
}

.pf-p-tag-margin-unset p {
    margin: unset;
}


.pf-unselectable {
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}

.pf-d-none {
    display: none !important;
}

.no-padding {
    margin: 0 !important;
}

.no-padding > .col-sm-6 {
    padding: 0 !important;
}

@media screen and (max-width: 1000px) {
    .pf-title-1 {
        font-size: 24px;
    }

    .pf-text-1 {
        line-height: 1.4em;
        font-size: 0.9em;
    }
}

.modal-open {
    overflow: auto !important;
    padding-right: 0 !important;
}

@media screen and (min-width: 1025px) {
    .pf-desktop-d-none {
        display: none !important;
    }

    .pf-ipad-except-d-none {
        display: none !important;
    }
}

@media screen and (min-width: 601px) {
    .pf-ipad-above-d-none {
        display: none !important;
    }
}

@media screen and (max-width: 1024px) {
    .pf-ipad-below-d-none {
        display: none !important;
    }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
    .pf-ipad-d-none {
        display: none !important;
    }
}

@media screen and (max-width: 600px) {
    .pf-mobile-d-none {
        display: none !important;
    }

    .pf-ipad-except-d-none {
        display: none !important;
    }
}
