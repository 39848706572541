.pf-header-mobile-business-menus {
    width: 100vw;
    background-color: #333333;
    color: #fff;
}

.pf-header-mobile-business-menus__item {
    box-sizing: border-box;
    padding: 0 5%;
    font-size: 17px;
    background-color: #333;
    font-weight: normal;
    position: relative;
    display: flex;
    border-left: 3px solid transparent;
    justify-content: space-between;
    align-items: center;
    height: 55px;
}

.pf-header-mobile-business-menus__item:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: calc(100vw - 30px);
    border-bottom: 1px solid #575757;
    left: 13px;
    bottom: 0;
}

.pf-header-mobile-business-menus__item a {
    text-decoration: none;
    display: block;
    color: #fff;
}

.pf-header-mobile-business-menus__item--active {
    color: #ef3f78 !important;
    border-left: 3px #ef3f78 solid;
}

.pf-header-mobile-business-menus__item--active a {
    color: #ef3f78 !important;
}

.pf-header-mobile-business-menus__item--opened {
    color: #ea3a77;
    top: -1px;
    border-top: 1px solid #333333;
}

.pf-header-mobile-business-menus__item.pf-header-mobile-business-menus__item--opened:before {
    display: none;
}

.pf-header-mobile-business-menus__item-trial a {
    background-color: #ef3f78;
    border-radius: 30px;
    border: 1px solid #ef3f78;
    color: #fff;
    padding: 3px 7px;
}

.pf-header-mobile-business-menus__menu {
    /*border-top: 1px solid rgba(213, 213, 213, 0.5);*/
    box-sizing: border-box;
    height: 70px;
    padding: 10px 15px;
    line-height: 30px;
    vertical-align: middle;
    font-size: 17px;
    background-color: #333;
    font-weight: 500;
    border-bottom: 1px solid rgba(213, 213, 213, 0.5);
}

.pf-header-mobile-business-menus__business {
    display: inline-block;
    background-color: #212529;
    border: 1px solid white;
    color: #fff;
    padding: 0 8px;
    font-size: 12px;
    cursor: pointer;
    margin: 8px 10px 8px 0;
    vertical-align: middle;
}

.pf-header-mobile-business-menus__consumer {
    display: inline-block;
    background-color: #212529;
    border: 1px solid transparent;
    color: #fff;
    padding: 0 8px;
    font-size: 12px;
    cursor: pointer;
    margin: 8px 10px 8px 0;
    vertical-align: middle;
}

.pf-header-mobile-business-menus__menu a {
    text-decoration: none;
    color: white;
}

.pf-header-mobile-business-menus__subitem {
    box-sizing: border-box;
    height: 50px;
    padding: 0 18px 0 25px;
    line-height: 30px;
    vertical-align: middle;
    border-bottom: 1px solid rgba(213, 213, 213, 0.5);
    color: #215ca0;
    background-color: #fff;
    font-size: 17px;
    border-left: 3px solid transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    transition: height 0.4s, opacity 0.2s;
}

.pf-header-mobile-business-menus__subitem a {
    color: #215ca0;
    display: block;
    text-decoration: none;
}

.pf-header-mobile-business-menus__subitem--active {
    color: #ef3f78;
    border-left: 3px #ef3f78 solid;
    background-color: #fdf2f6;
}

.pf-header-mobile-business-menus__subitem--active a {
    color: #ef3f78;
}

.pf-header-mobile-business-menus__subitem--opened {
    color: #ef3f78 !important;
}

.pf-header-mobile-business-menus__subitem--opened a {
    color: #ef3f78;
}

.pf-header-mobile-business-menus__subitem-category {
    font-size: 1.1rem;
    box-sizing: border-box;
    /*padding: 10px 15px 10px 50px;*/
    padding: 0 18px 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    line-height: 30px;
    vertical-align: middle;
    border-bottom: 1px solid rgba(213, 213, 213, 0.5);
    color: #215ca0;
    background-color: #fff;
    border-left: 3px solid transparent;
    transition: height 0.4s, opacity 0.2s;
}

.pf-header-mobile-business-menus__subitem-category a {
    color: #215ca0;
}

.pf-header-mobile-business-menus__subitem-category--opened {
    color: #ef3f78 !important;
}

.pf-header-mobile-business-menus__subitem-category-item {
    box-sizing: border-box;
    /*min-height: 55px;*/
    height: 55px;
    padding: 0 18px 0 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 30px;
    vertical-align: middle;
    border-bottom: 1px solid rgba(213, 213, 213, 0.5);
    color: #215ca0;
    background-color: #fff;
    font-size: 17px;
    border-left: 3px solid transparent;
    transition: height 0.4s, opacity 0.2s;
}

.pf-header-mobile-business-menus__subitem-category-subitem {
    padding: 0 18px 0 90px;
}

.pf-header-mobile-business-menus__subitem-category-item a {
    color: #215ca0;
    display: block;
    text-decoration: none;
}

.pf-header-mobile-business-menus__subitem-category-item--active {
    color: #ef3f78;
    border-left: 3px #ef3f78 solid;
    background-color: #fdf2f6;
}

.pf-header-mobile-business-menus__subitem-category-item--active a {
    color: #ef3f78;
}

.pf-header-mobile-business-menus__icon {
    float: right;
    line-height: 28px;
}

.pf-header-mobile-business-menus__resources {
    padding: 20px 8% 0;
    height: auto;
    opacity: 1;
    background-color: #ffffff;
    transition-duration: 0.4s, 0.2s;
    transition-property: height, opacity;
    overflow: hidden;
}

.pf-header-mobile-business-menus__resource {
    display: block;
    line-height: 1.5;
    margin-bottom: 12px;
    color: #212529;
    font-weight: 500;
}

.pf-header-mobile-business-menus__resource:hover {
    color: #212529 !important;
}

.pf-header-mobile-business-menus__resource--active {
    color: #f23b78 !important;
}

.pf-header-mobile-business-menus__resource:before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 2px;
    background-color: #d7d7d7;
    vertical-align: middle;
    margin-right: 8px;
}

.pf-header-mobile-business-menus__resource__sub {
    font-weight: normal;
    display: block;
    color: #575757;
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 10px;
    padding-left: 14px;
}

.pf-header-mobile-business-menus__featured {
    color: #212529;
    display: flex;
    line-height: 14px;
    padding-bottom: 20px;
}

.pf-header-mobile-business-menus__featured__img {
    width: 30%;
    background-color: #eee;
    margin-right: 15px;
    background-size: auto 100%;
}

.pf-header-mobile-business-menus__featured__type {
    font-size: 14px;
    color: #f23b77;
    font-weight: 500;
    margin-bottom: 8px;
}

.pf-header-mobile-business-menus__featured__title {
    margin-bottom: 8px;
    font-size: 14px;
    display: block;
    color: #212529;
}

.pf-header-mobile-business-menus__featured__link {
    font-size: 13px;
}

.pf-header-mobile-business-menus--collapse {
    height: 0 !important;
    opacity: 0 !important;
    overflow: hidden;
    padding-top: 0;
    transition: height 0.4s, opacity 0.2s;
    border: none;
}

.pf-header-mobile-business-menus__arrow {
    width: 12px;
    height: 7px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
}

.pf-header-mobile-business-menus__arrow.up {
    background-image: url('../../../assets/images/icon/fa-caret-up-pink.svg');
}

.pf-header-mobile-business-menus__arrow.down {
    background-image: url('../../../assets/images/icon/fa-caret-down.svg');
}

.pf-header-mobile-business-menus__arrow.grey {
    background-image: url('../../../assets/images/icon/fa-caret-down-grey.svg');
}

.mobile-business-menu {
    background-color: #ffffff;
    padding-left: 8%;
    /*height: 1351px;*/
    opacity: 1;
    transition-duration: 0.4s, 0.2s;
    transition-property: height, opacity;
    overflow-x: hidden;
}

.mobile-business-menu__title {
    font-size: 22px;
    color: #212529;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
}

.mobile-business-menu__item {
    color: #212529;
    font-size: 14px;
    display: block;
    line-height: 2.5;
}

.mobile-business-menu__item--active {
    color: #f23b77;
}

.mobile-business-menu__divider {
    border-bottom: 1px solid #e5e5e5;
    margin: 10px 0 32px;
    width: 100vw;
    position: relative;
    left: -8%;
}

.mobile-business-menu__viewall {
    font-size: 14px;
    color: #007bff;
    display: block;
    line-height: 2;
    margin: 40px 0 8px;
}

.mobile-business-menu__contact {
    color: #ffffff;
    background-color: #f23b77;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 30px;
}

.mobile-business-menu__subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #f23b77;
}

@media screen and (min-width: 374px) {
    .pf-header-mobile-business-menus__menu {
        display: none;
    }
}
