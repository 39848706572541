.pf-header__logo {
    width: 150px;
}

.pf-header__logo img {
    max-width: 150px;
    width: 100%;
    vertical-align: middle;
    aspect-ratio: 150/29;
}

@media screen and (max-width: 1000px) {
    .pf-header__logo {
        width: 119px;
        height: 48px;
    }
}

@media screen and (max-width: 400px) {
    .pf-header__logo {
        width: 100px;
    }
}
