.pink-button--icon {
    padding: 12px 40px;
    height: fit-content;
    line-height: 1;
    font-size: 1rem;
    display: inline-flex;
    box-sizing: border-box;
    min-height: 52px;
    overflow: initial;
    justify-content: center;
}

.pink-button--icon > div {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.pink-button--icon > div > div {
    line-height: 1.2;
    text-align: left;
}

.pink-button--icon > div > img {
    margin-right: 8px;
}

.pink-button--round {
    border-radius: 38px;
    padding: 12px 40px;
    box-sizing: border-box;
    display: inline-flex;
    min-height: 44px;
    overflow: initial;
    justify-content: center;
    align-items: center;
    height: fit-content;
    font-size: 1rem;
    line-height: 1.2;
}

@media only screen and (max-width: 600px) {
    .pink-button--round,
    .pink-button--icon {
        display: flex;
    }

    .pf-full-btn--mb {
        display: block;
    }
}
