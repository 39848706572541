.video-banner--mobile {
    display: none;
}

.video-banner {
    width: 100%;
    overflow: visible;
    position: relative;
    display: flex;
    align-items: center;
}

.video-banner video {
    z-index: -100;
    width: 100%;
}

.video-banner-content {
    width: 45%;
}

.video-banner-content-title {
    font-size: 3rem;
    line-height: 47px;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: #212529;
    margin-bottom: 20px;
}

.video-banner-content-title > h1 {
    margin: 0;
}

.video-banner-content-title.icon {
    font-size: 2.5rem;
    font-weight: 500;
}

.video-banner-content-title.icon span {
    vertical-align: middle;
}

.video-banner-content-title.icon > img {
    margin-right: 15px;
    width: 77px;
    aspect-ratio: 1;
}

.video-banner-content-desc {
    font-size: 1rem;
    color: #333333;
    margin-bottom: 20px;
}

.video-banner-content-icons {
    font-size: 1.125rem;
}

.video-banner-content-full-icon {
    display: block;
}

.video-banner-content-full-icon.icon img {
    aspect-ratio: initial;
    max-width: 330px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
}

.consumer-home-banner {
    height: calc(100vw * 612 / 1366);
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
    .video-banner-content-title {
        font-size: 2.4rem;
    }

    .video-banner-content-icons {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 1100px) and (min-width: 1025px) {
    .video-banner-content-title {
        font-size: 1.8rem;
    }

    .video-banner-content-icons {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 1024px) and (min-width: 601px) {
    .video-banner-content-title {
        flex-direction: column;
        margin-bottom: 0;
        text-align: left;
        align-items: initial;
        font-size: 1.875rem;
    }

    .video-banner-content-title.icon img {
        margin-bottom: 8px;
    }

    .video-banner-content-icons {
        padding: 20px 0 30px;
        border-bottom: 1px solid #f2f2f2;
        text-align: center;
    }

    .video-banner-content-icons > div > div {
        justify-content: center;
    }

    .video-banner-content-full-icon.icon img {
        max-width: 180px;
        margin: 20px 0 10px;
    }

    .video-banner-content-icons-pad > div {
        margin: 0 auto 20px;
    }
}

@media screen and (max-width: 800px) and (min-width: 601px) {
    .video-banner-content-title {
        font-size: 1.5rem;
        line-height: 27px;
        margin-top: 98px;
    }

    .video-banner-content-full-icon.icon img {
        max-width: 180px;
    }
}

@media screen and (max-width: 600px) {
    .video-banner--mobile {
        display: block;
        margin-bottom: 40px;
        text-align: left;
    }

    .video-banner {
        display: none;
    }

    .video-banner-content-icons {
        font-size: 1.1rem;
    }

    .video-banner-content-title__store {
        display: block;
        margin-top: 8px;
    }

    .video-banner-content-title__store img {
        width: 100%;
        max-width: 126px;
    }

    .video-banner-content {
        width: 90%;
        margin: 0 auto;
    }

    .video-banner-content-title {
        font-size: 1.875rem;
        line-height: 36px;
    }

    .video-banner-content-full-icon.icon img {
        max-width: 266px;
    }

    .consumer-home-banner {
        height: calc(100vw * 478 / 390);
    }
}
