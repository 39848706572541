.pf-header-mobile-consumer-menus {
    width: 100vw;
    background-color: #fff;
    color: #fff;
    overflow-x: hidden;
}

.pf-header-mobile-consumer-menus__item {
    box-sizing: border-box;
    min-height: 50px;
    padding: 10px 5%;
    line-height: 30px;
    vertical-align: middle;
    font-size: 17px;
    background-color: #333333;
    font-weight: 500;
    border-bottom: 1px solid #676767;
    display: block;
}

.pf-header-mobile-consumer-menus__item--opened {
    color: #ea3a77;
}

.pf-header-mobile-consumer-menus__subitem {
    box-sizing: border-box;
    height: 50px;
    padding: 10px 15px 10px 25px;
    line-height: 30px;
    vertical-align: middle;
    border-bottom: 1px solid rgba(213, 213, 213, 0.8);
    color: #212529;
    background-color: #292929;
    font-size: 17px;
    border-left: 3px solid transparent;
}

.pf-header-mobile-consumer-menus__subitem a {
    color: #fff;
    display: block;
    text-decoration: none;
}

.pf-header-mobile-consumer-menus__subitem--active {
    color: #ef3f78;
    border-left: 3px #ef3f78 solid;
}

.pf-header-mobile-consumer-menus__subitem--active a {
    color: #ef3f78;
    text-decoration: none;
}

.pf-header-mobile-consumer-menus__subitem-category {
    font-size: 14px;
    box-sizing: border-box;
    padding: 10px 15px 10px 25px;
    height: 30px;
    line-height: 10px;
    vertical-align: middle;
    border-bottom: 1px solid rgba(213, 213, 213, 0.5);
    color: #212529;
    background-color: rgba(174, 174, 174, 0.1);
}

.pf-header-mobile-consumer-menus__menu {
    border-top: 1px solid rgba(213, 213, 213, 0.5);
    box-sizing: border-box;
    height: 70px;
    padding: 10px 15px;
    line-height: 30px;
    vertical-align: middle;
    font-size: 17px;
    background-color: #212529;
    font-weight: 500;
    border-bottom: 1px solid rgba(213, 213, 213, 0.5);
}

.pf-header-mobile-consumer-menus__business {
    display: inline-block;
    background-color: #333;
    border: 1px solid transparent;
    color: #fff;
    padding: 0 8px;
    font-size: 12px;
    cursor: pointer;
    margin: 8px 10px 8px 0;
    vertical-align: middle;
}

.pf-header-mobile-consumer-menus__consumer {
    display: inline-block;
    background-color: #212529;
    border: 1px solid white;

    color: #fff;
    padding: 0 8px;
    font-size: 12px;
    cursor: pointer;
    margin: 8px 10px 8px 0;
    vertical-align: middle;
}

.pf-header-mobile-consumer-menus__menu a {
    text-decoration: none;
    color: white;
}

.pf-header-mobile-consumer-menus__icon {
    float: right;
    line-height: 28px;
}

@media screen and (min-width: 374px) {
    .pf-header-mobile-consumer-menus__menu {
        display: none;
    }
}
