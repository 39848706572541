.btfv-action-button {
    background-image: linear-gradient(
        110deg,
        #ffb741 -48%,
        #f23e76 89%,
        #f23b77 0
    );
    color: #ffffff;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    width: 80%;
    padding: 9px 0;
    margin: auto;
    text-align: center;
    cursor: pointer;
    border: none;
}

.btfv-action-button span {
    vertical-align: middle;
}
