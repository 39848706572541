.pf-header-cookiestatement {
    width: 100%;
    background-color: rgba(33, 37, 41, 0.9);
    min-height: 60px;
    position: fixed;
    bottom: 0;
    z-index: 999;
}

.pf-header-cookiestatement-container {
    padding: 34px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pf-header-cookiestatement-hide {
    display: none;
}

.pf-header-cookiestatement-text {
    font-size: 18px;
    line-height: 1.33;
    text-align: left;
    color: #fff;
    margin-right: 35px;
    flex: 0 0 80%;
}

.pf-header-cookiestatement-close {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background-color: #f23b77;
    padding: 14px 40px;
    border-radius: 4px;
    border: 1px solid transparent;
    margin-left: 35px;
}

.pf-header-cookiestatement-close:hover {
    border: 1px solid #ff72a0;
    background-color: #ff72a0;
}

@media screen and (max-width: 600px) {
    .pf-header-cookiestatement {
        background-color: #333333;
    }

    .pf-header-cookiestatement-container {
        flex-direction: column;
        padding: 21px 0 27px;
    }

    .pf-header-cookiestatement-close {
        width: 100%;
        margin: 0 auto;
        max-width: 270px;
    }

    .pf-header-cookiestatement-text {
        font-size: 14px;
        margin: 0 auto 10px;
    }
}
