.btfv-event-card__wrapper {
    padding: 20px 16px 15px;
    margin: 0 5.8% 44px 0;
    width: calc(100% / 3 - 4%);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    transition-duration: 300ms;
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;
}

.btfv-event-card__wrapper:nth-child(3n) {
    margin-right: 0;
}

.btfv-event-card__wrapper:hover {
    transform: scale(1.03);
}

.btfv-event-card__image {
    background-color: #eee;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    border: solid 1px #d7d7d7;
}

.btfv-event-card__image > img {
    width: 100%;
}

.btfv-event-card__button {
    background-color: #ff8193;
    opacity: 0.9;
    color: #ffffff;
    padding: 6px 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.btfv-event-card__button__text {
    vertical-align: middle;
}

.btfv-event-card__label {
    margin: -20px -16px 16px;
    padding: 8px 0;
    background-color: #73a9c6;
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
}

.btfv-event-card__title {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 700;
    color: #212529;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    /* autoprefixer: off */
    margin: 11px 0 9px;
    -webkit-line-clamp: 3;
    height: 74px;
}

.btfv-event-card__speakers {
    margin-bottom: 14px;
}

.btfv-event-card__speaker {
    position: relative;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
}

.btfv-event-card__speaker__img {
    width:72px;
    height:72px;
    border-radius: 50px;
    margin-right: 10px;
}

.btfv-event-card__speaker__name,
.btfv-event-card__speaker__brand {
    font-weight: 500;
    margin-bottom: 6px;
    font-size: 15px;
    color: #212529;
}

.btfv-event-card__speaker__brand {
    font-weight: bold;
}

.btfv-event-card__speaker__position {
    font-size: 12px;
    color: #575757;
}

@media screen and (max-width: 768px) {
    .btfv-event-card__wrapper {
        width: 100%;
        margin: 0 0 30px 0;
    }

    .btfv-event-card__speaker__img{
        width:78px;
        height:78px;
        border-radius: 50px;
    }

    .btfv-event-card__speaker {
        margin-bottom: 18px;
    }
}
