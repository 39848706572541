.pf-header__language {
  display: block;
  vertical-align: middle;
  cursor: pointer;
}

.pf-header__language img {
  line-height: 25px;
  vertical-align: middle;
  height: 22px;
}

.pf-header__language span {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}

.pf-language-menu {
  color: #212529;
  font-size: 16px;
  background-color: #fff;
}

.pf-language-menu__item {
  text-align: center;
  width: 200px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.pf-language-menu__item a {
  background-color: #fff;
  text-decoration: none;
  line-height: 40px;
  color: #212529;
  display: block;
}

.pf-language-menu__item a:hover {
  background-color: #f0f0f0;
  color: #212529;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .pf-header__language {
    display: none;
  }

  .pf-language-menu {
    background-color: rgba(255, 255, 255, 0);
  }

  .pf-language-menu__item {
    background-color: rgba(81, 81, 81, 1);
    width: 100vw;
    text-align: center;
    border-top: 1px solid #d7d7d7;
    border-bottom: 0;
  }

  .pf-language-menu__item a {
    background-color: rgba(81, 81, 81, 1);
    color: #fff;
    display: block;
  }

  .pf-language-menu__item a:hover {
    background-color: rgba(81, 81, 81, 1);
    color: #fff;
    display: block;
  }
}
