.function-card {
    box-sizing: border-box;
    background-color: #ffffff;
    font-weight: 700;
    text-align: center;
    color: #212529;
    -webkit-appearance: button !important;
    outline: 0 !important;
    margin-bottom: 20px;
    height: 148px;
    box-shadow: 0 0 14px 0 #5588a72e;
    padding: 10px 20px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.function-card:last-child {
    margin-bottom: 60px;
}

.function-card > .cta-wrapper {
    text-align: right;
}

.function-card > .cta-wrapper,
.function-card > .icon {
    flex: 0 0 290px;
}

.function-card > .icon {
    display: flex;
    align-items: center;
}

.function-card > .icon img {
    margin-right: 15px;
    aspect-ratio: 1;
    max-width: 112px;
    width: 100%;
}

.function-card-content {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4f5051;
}

.function-card-content > span {
    text-transform: capitalize;
}

.function-card > .cta-wrapper > .cta {
    padding: 0 20px;
    text-transform: initial !important;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    box-sizing: border-box;
    max-width: 100%;
}

.function-card-label {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-transform: uppercase;
}

.function-card-content-link {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.02em;
    text-align: center;
    text-decoration-line: underline !important;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: auto;
    display: block;
}

.signin-card--cta-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.signin-card--cta-arrow img {
    margin-left: 8px;
    width: 16px;
}

@media screen and (max-width: 1024px) {
    .function-card > .cta-wrapper,
    .function-card > .icon {
        flex: 0 0 200px;
    }

    .function-card > .icon img {
        max-width: 80px;
    }

    .function-card-label,
    .function-card-content,
    .function-card > .cta-wrapper > .cta {
        font-size: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .function-card {
        padding: 14px;
        width: 100%;
        margin: 0 auto 20px;
        flex-direction: column;
        height: initial;
    }

    .function-card > .cta-wrapper,
    .function-card > .icon {
        flex-basis: initial;
    }

    .function-card > .icon {
        margin-bottom: 15px;
        width: 100%;
    }

    .function-card > .icon img {
        margin-right: 20px;
        max-width: 90px;
    }

    .function-card-label {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.2;
        text-align: left;
    }

    .function-card-content {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        text-align: left;
        color: #4f5051;
        margin-top: 5px;
        text-transform: initial;
    }

    .function-card > .cta-wrapper > .cta {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        font-size: 1.1rem;
    }

    .function-card > .cta-wrapper {
        width: 100%;
    }

    .function-card-content-link {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 0.02em;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-underline-position: from-font;
        text-decoration-skip-ink: auto;
        margin-top: 8px;
        text-transform: initial;
    }
}
