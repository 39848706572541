.pf-subheader-pop-menu {
    color: #212529;
    display: inline-block;
    padding: 20px 0;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
}

.pf-subheader-pop-menu__container {
    display: flex;
    max-width: 85%;
}

.pf-subheader-pop-menu__subtitle {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.19;
    padding: 7px 0 7px 3px;
    color: #ea3a77;
    border-bottom: 1px solid #ea3a77;
}

.pf-subheader-pop-menu__menus {
    float: left;
    box-sizing: border-box;
    padding-right: 45px;
}

.pf-subheader-menu {
    padding: 0 7.5% 40px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.pf-subheader-menu--container {
    box-sizing: border-box;
    justify-content: space-between;
    display: flex;
    width: 100%;
}

.pf-subheader-menu__left {
    padding-top: 30px;
    margin-bottom: 24px;
    box-sizing: border-box;
    width: 100%;
}

.pf-subheader-menu__right {
    padding-top: 24px;
    padding-left: 2.5%;
    width: 34%;
    box-sizing: border-box;
    border-left: 1px solid #e5e5e5;
}

.pf-subheader-menu__menus {
    box-sizing: border-box;
    padding-right: 45px;
    padding-bottom: 40px;
    min-width: 20%;
}

.pf-subheader-menu__menus:last-child {
    padding-right: 0;
}

.pf-subheader-menu__viewall {
    font-size: 0.875rem;
    color: #007bff;
    display: block;
    line-height: 2;
    margin: 0 10px;
}

.pf-subheader-menu__button {
    text-transform: uppercase;
    border-radius: 4px;
    padding: 0 15px !important;
    font-size: 0.875rem !important;
    font-weight: 500;
    margin: 0 10px;
    height: 30px !important;
    line-height: 30px !important;
}

.pf-subheader-pop-menu__right {
    float: right;
    padding-left: 45px;
    width: 100%;
    box-sizing: border-box;
}

.btf-virtual-text {
    color: #212529;
    text-decoration: none;
    line-height: 1.17;
    font-size: 12px;
    display: block;
    padding: 7px 0 7px 6px;
    border-left: 3px #fff solid;
    cursor: pointer;
}

.btf-virtual-text-hover {
    color: #ef3f78;
    background-color: #fdf2f6;
    border-left: 3px #ef3f78 solid;
}

.pf-subheader-pop-menu__menus-second-layer {
    border-left: 3px solid #f2f2f2;
    padding: 0 0 0 7px;
    width: 100%;
    margin-left: 40px;
}

.pf-subheader-pop-menu__menus:last-child {
    padding-right: 0;
}

.pf-subheader-pop-menu__menus a,
a.pf-subheader-menu__link {
    color: #212529;
    text-decoration: none;
    line-height: 1.17;
    font-size: 0.875rem;
    display: block;
    padding: 7px 0 7px 3px;
    border-left: 3px #fff solid;
}

a.pf-subheader-pop-menu__menus--active,
a.pf-subheader-menu__link--active {
    color: #ef3f78;
    background-color: #fdf2f6;
    text-decoration: none;
    border-left: 3px #ef3f78 solid;
}

.pf-subheader-pop-menu-title {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.19;
    margin-bottom: 12px;
    width: 92%;
    color: #212529;
}

.pf-subheader-pop-menu-desc {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 143%;
    color: #212529;
    margin-bottom: 10px;
}

.pf-subheader-menu__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pf-subheader-menu__button-wrapper > a {
    margin: 0 10px;
}

@media screen and (min-width: 1280px) {
    .pf-subheader-pop-menu__menus {
        white-space: nowrap;
    }
}

@media screen and (min-width: 1025px) {
    .pf-subheader-pop-menu__menus a:hover,
    a.pf-subheader-menu__link:hover {
        color: #ef3f78;
        background-color: #fdf2f6;
        text-decoration: none;
        border-left: 3px #ef3f78 solid;
    }
}

@media screen and (max-width: 1279px) {
    .pf-subheader-pop-menu__menus-second-layer {
        margin-left: 20px;
        float: initial;
        border-left: none;
    }

    .pf-subheader-pop-menu__menus a {
        display: table;
    }
}

@media screen and (max-width: 820px) {
    .pf-subheader-menu {
        flex-direction: column;
    }

    .pf-subheader-menu__left {
        border: none;
        margin-bottom: 0;
        width: 100%;
    }

    .pf-subheader-menu__right {
        padding-left: 0;
        padding-top: 40px;
        margin-bottom: 24px;
        width: 100%;
        border-left: none;
        border-top: 1px solid #f2f2f2;
    }

    .pf-subheader-pop-menu__subtitle {
        font-size: 0.875rem;
        line-height: 28px;
        white-space: break-spaces;
        height: fit-content;
    }

    .pf-subheader-pop-menu__menus {
        float: left;
    }

    .pf-subheader-pop-menu__left a {
        font-size: 0.875rem;
        line-height: 22px;
    }

    .pf-subheader-pop-menu__right a {
        font-size: 0.75rem;
    }

    .pf-subheader-pop-menu-desc {
        width: 95%;
        max-width: none;
    }

    .pf-subheader-menu__button-wrapper {
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 600px) {
    .pf-subheader-menu {
        background-color: #fff;
        padding: 0 5%;
    }

    .pf-subheader-menu__menus {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .pf-subheader-pop-menu__subtitle {
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 26px;
        color: #f23b77;
        border: none;
    }

    .pf-subheader-pop-menu__menus a,
    a.pf-subheader-menu__link {
        font-size: 1.1rem;
        line-height: 2.5;
        padding: 0;
    }

    .pf-subheader-menu__viewall {
        font-size: 1.1rem;
    }

    .pf-subheader-pop-menu__container {
        flex-direction: column-reverse;
    }

    .pf-subheader-pop-menu__container {
        max-width: 90%;
    }

    .pf-subheader-pop-menu__right {
        padding-left: 0;
    }

    a.pf-subheader-pop-menu__menus--active,
    a.pf-subheader-menu__link--active {
        color: #ef3f78;
        background-color: transparent;
        text-decoration: none;
        border-left: none;
    }

    .pf-subheader-menu__right {
        margin-bottom: 0;
    }

    .pf-subheader-menu--container {
        display: block;
    }

    .pf-subheader-menu__button-wrapper {
        display: flex;
        margin-top: 30px;
        flex-wrap: wrap;
        justify-content: left;
    }

    .pf-subheader-menu__button-wrapper > a {
        flex: 0 0 calc(50% - 20px);
        margin-bottom: 10px;
    }

    .pf-subheader-menu__button,
    .pf-subheader-menu__viewall {
        margin: 0 10px 10px 0;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.1) and (min-width: 1025px),
    (min-resolution: 144dpi) and (min-width: 1025px) {
    .pf-subheader-menu__link {
        font-size: 0.75rem !important;
    }

    .pf-subheader-pop-menu__menus a,
    a.pf-subheader-menu__link {
        padding: 5px 0 5px 3px;
    }

    .pf-subheader-menu__menus {
        padding-bottom: 20px;
    }
}
