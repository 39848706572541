.app-feature-menu--wrapper {
    display: flex;
    margin: 0 auto;
    width: 100%;
    padding-top: 30px;
    justify-content: space-between;
    max-width: 1300px;
}

.app-feature-menu--wrapper > div {
    flex: 0 0 33.33333%;
    box-sizing: border-box;
    padding: 0 30px;
}

.app-feature-menu--link-container a {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #212529;
    display: flex;
    margin-bottom: 15px;
    /*height: calc(1.2 * 0.875rem * 2);*/
}

.app-feature-menu--link-container a:hover {
    color: #f23b77;
}

.app-feature-menu--label {
    color: #e62d82;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 20px;
}

.app-feature-menu--link-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.app-feature-menu--link-container a {
    flex-basis: 48%;
}

.app-feature-menu--wrapper > div:first-child {
    padding-left: 0;
}

.app-feature-menu--wrapper > div:nth-child(2) {
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: #dddddd;
}

.app-feature-menu--wrapper > div:last-child {
    padding-right: 0;
}

.app-feature-menu--title-active {
    color: #f23b77 !important;
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .app-feature-menu--link-container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 600px) {
    .app-feature-menu--wrapper {
        flex-direction: column;
        padding-top: 0;
    }

    .app-feature-menu--wrapper > div:nth-child(2) {
        border: none;
    }

    .app-feature-menu--link-container a {
        flex-basis: 100%;
    }

    .app-feature-menu--wrapper > div {
        padding: 0;
    }

    .app-feature-menu--label {
        border-bottom: 1px solid #cecece;
        padding: 20px 0;
        font-size: 1.1rem;
        margin-bottom: 0;
        font-weight: 700;
    }

    .app-feature--menu {
        background-color: #e4e4e4;
    }

    .app-feature-menu--label--active {
        background-color: #fff;
    }

    .app-feature-menu--link-container {
        padding: 20px 5%;
        margin: 0 auto;
    }

    .app-feature-menu--wrapper > div a{
        font-size: 1.1rem;
    }
}
