.pf-header__mobile-menus {
    display: none;
}

@media screen and (max-width: 600px) {
    .pf-header__mobile-menus {
        display: block;
    }

    .pf-header__mobile-menus-icon {
        vertical-align: middle;
        cursor: pointer;
        color: #fff;
    }

    .nft-header__mobile-menus-icon {
        height: initial;
        line-height: initial;
        margin-right: 14px;
    }
}
