.subheader-resources__left {
    width: 54%;
    border-right: 1px solid #e5e5e5;
    padding: 30px 9% 35px 35px;
    box-sizing: border-box;
}

.subheader-resources__featured {
    display: flex;
    margin-bottom: 20px;
}

.subheader-resources__featured:last-child {
    margin-bottom: 0;
}

.subheader-resources__featured__img {
    width: 80px;
    height: 80px;
    background-color: #eee;
    background-repeat: no-repeat;
    background-size: auto 100%;
    margin-right: 15px;
}

.subheader-resources__featured__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 100px);
}

.subheader-resources__featured__type {
    font-size: 13px;
    color: #f23b77;
    font-weight: bold;
    margin-bottom: 6px;
}

.subheader-resources__featured__title {
    font-size: 14px;
    margin-bottom: 7px;
    color: #212529;
}

.subheader-resources__featured__link {
    font-size: 12px;
}

.subheader-resources__right {
    padding: 30px 0 35px 4.6%;
    box-sizing: border-box;
    display: flex;
}

.subheader-resources__group {
    margin-right: 20px;
    width: 45%;
    padding-right: 0;
}

.subheader-resources__group:last-child {
    margin-right: 0;
}

.subheader-resources__link {
    font-size: 0.875rem;
    color: #212529;
    font-weight: 500;
    margin-bottom: 7px;
    display: inline-block !important;
}

.subheader-resources__link:before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 2px;
    background-color: #d7d7d7;
    vertical-align: middle;
    margin-right: 8px;
}

.subheader-resources__link__sub {
    font-size: 14px;
    margin-left: 26px;
    border-left: 1px solid #d7d7d7 !important;
}

.subheader-resources__link__sub:hover {
    border-left: 1px solid #f23b77;
}

.subheader-resources__link__sub a {
    padding-left: 15px;
}

.pf-subheader-pop-menu__menus--hover {
    cursor: pointer;
    padding: 7px 0 7px 5px;
    border-left: 3px #fff solid;
}

.pf-subheader-pop-menu__menus--hover:hover {
    color: #ef3f78;
    background-color: #fdf2f6;
    text-decoration: none;
    border-left: 3px #ef3f78 solid;
}

@media screen and (max-width: 1366px) {
    .subheader-resources__left {
        padding-right: 35px;
    }
}

@media screen and (max-width: 768px) {
    .subheader-resources__group {
        width: 80%;
        padding-right: 20px !important;
    }

    .subheader-resources__left {
        padding-right: 20px;
        width: 60%;
    }

    .subheader-resources__right {
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .subheader-resources__left {
        border-right: none;
        border-top: 2px solid rgb(229, 229, 229);
        width: 100%;
        padding-left: 0;
        padding-top: 30px;
    }

    .pf-subheader-pop-menu__right {
        padding-left: 0;
    }

    .subheader-resources__group {
        width: 100%;
    }
}
