body {
    margin: 0;
    padding: 0;
    /*font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "", "Oxygen",*/
    /*  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*  sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.termly-styles-button-e6bc23:not(:last-child) {
    color: #000000 !important;
    background: #fff !important;
    border: 1px solid #000000 !important;
}
